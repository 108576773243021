import {
  useRef,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

import RVideo from 'shared/components/recorder/Rvideo'
import get from 'lodash/get'
import VoiceDetector from 'shared/components/VoiceDetector'
import BackgroundRemover from 'shared/components/BackgroundRemover'
import { InteractionT } from 'shared/types/facesign'

const apiKey = process.env.REACT_APP_HEYGEN_KEY
const SERVER_URL = 'https://api.heygen.com'

export interface IHeyGenConnect {
  say: (text: string) => void
  stop: () => Promise<void>
  play: () => void
}

type Props = {
  onAvatarPlayingFinished: (latency: number) => void
  thereWasAnError?: string
  setThereWasAnError?: (v: string) => void
  onSessionStarted: () => void
  isRecording: boolean
  permissionsGranted: boolean
  handleChunk: (videoBlob: Blob, mimeType: string, role: 'avatar') => void
  logEvent: (eventType: InteractionT.LogTypeT, data?: object) => void
  setDuration: (v: number) => void
  handleVideoClick: () => void
}

const HeyGenConnect: ForwardRefRenderFunction<IHeyGenConnect, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    setThereWasAnError,
    onSessionStarted,
    isRecording,
    permissionsGranted,
    handleChunk,
    logEvent,
    setDuration,
    handleVideoClick
  },
  ref
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoMutedRef = useRef<HTMLVideoElement>(null)
  const sessionIdRef = useRef()
  const peerConnectionRef = useRef<RTCPeerConnection>()
  const videoRecorderRef = useRef<RVideo>()
  const streamRef = useRef<MediaStream | null>(null)
  const streamHasBeenSet = useRef<boolean>(false)
  const sdpRef = useRef<any>(null)
  const jobRef = useRef<() => void | null>(null)
  const jobStartTime = useRef<number>(0)
  const jobRunTime = useRef<number>(0)
  const [voiceDetectorEnabled, setVoiceDetectorEnabled] = useState(false)
  const heygenStartRespondedRef = useRef(false)
  const messagePartsRef = useRef<string[]>([])

  useImperativeHandle(ref, () => ({
    say: (t: string) => {
      console.log('say', t)
      startTalk(t)
    },
    stop: stopSession,
    play
  }))

  useEffect(() => {
    connect()
    return () => {
      stopSession()
      const pc = peerConnectionRef.current
      if (pc) {
        pc.close()
      }
    }
  }, [])

  const play = () => {
    if (!streamHasBeenSet.current) {
      playVideo()
    }
  }

  useEffect(() => {
    if (permissionsGranted) {
      // setTimeout(playVideo, 500)
      playVideo()
    }
  }, [permissionsGranted])

  // const onVideoRecordingStopped = async () => {
  //   if (onRecordingComplete && videoRecorderRef.current) {
  //     console.log(
  //       'HEYGEN: onVideoRecordingStopped, blobs amount',
  //       videoRecorderRef.current.blobs.length
  //     )
  //     console.log('recorder vide type', videoRecorderRef.current.mimeType)

  //     const recordedBlobs = videoRecorderRef.current.blobs
  //     const videoBlob =
  //       recordedBlobs.length === 1
  //         ? recordedBlobs[0]
  //         : new Blob(recordedBlobs, {
  //           type: videoRecorderRef.current.mimeType
  //         })
  //     onRecordingComplete(
  //       videoBlob,
  //       videoRecorderRef.current.mimeType,
  //       videoRecorderRef.current.duration
  //     )
  //   }
  // }

  const avatarCompleted = () => {
    logEvent('avatar finished talking')
    if (messagePartsRef.current.length > 0) {
      logEvent('some parts of the phrase are left', {
        parts: messagePartsRef.current
      })
      startTalk(messagePartsRef.current[0])
    } else {
      onAvatarPlayingFinished(jobRunTime.current - jobStartTime.current)
    }
  }

  const startTalk = async (msg: string) => {
    console.log('startTalk', msg)
    // console.log('startTalk, d-id key', process.env.REACT_APP_D_ID_KEY)
    const sessionId = sessionIdRef.current
    if (sessionId) {
      logEvent('start talk', { text: msg })
      const ar =
        msg.length > 100
          ? msg.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|')
          : [msg]
      console.log('PHRASES ARRAY', ar)
      if (messagePartsRef.current.length > 0) {
        msg = messagePartsRef.current[0]
        messagePartsRef.current.shift()
      } else if (ar.length > 1) {
        logEvent('the message is split on several phrases', { parts: ar })
        msg = ar[0]
        ar.shift()
        messagePartsRef.current = ar
      }
      logEvent('HeyGen: call streaming.talk', { text: msg })
      const response = await fetch(`${SERVER_URL}/v1/streaming.task`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey
        },
        body: JSON.stringify({
          session_id: sessionId,
          text: msg,
          task_type: 'repeat'
        })
      })
      if (!response.ok && response.status !== 400) {
        console.error('Server error')
        // updateStatus(
        //   statusElement,
        //   'Server Error. Please ask the staff if the service has been turned on'
        // )
        logEvent('ERROR: HeyGen: streaming.talk - FAIL', {
          code: response.status,
          status: response.statusText
        })
        throw new Error('Server error')
      } else {
        const data = await response.json()
        console.log('start talk response', data)
        console.log(data.code, data.message)
        logEvent('HeyGen: streaming.talk - OK', data)
        if (data.code === 10002 || data.code === 10001) {
          console.log('try to call talk in 500 ms')
          logEvent(
            'WARNING: HeyGen: streaming.talk - data.code is 10002 will retry in 500ms'
          )
          setTimeout(() => startTalk(msg), 500)
        } else {
          const duration = get(data, 'data.duration_ms')
          console.log('avatar speech duration should be', duration)
          if (duration) {
            jobStartTime.current = Date.now()
            console.log('jobStartTime', jobStartTime)
            jobRef.current = () => {
              setTimeout(() => {
                avatarCompleted()
              }, Number(duration - 500))
            }
            setVoiceDetectorEnabled(true)
          }
        }
      }
    } else {
      console.warn('cannot run talk task, session is not initialized')
    }
  }

  const playVideo = () => {
    console.log(
      '%cplayVideo, permisssions granted',
      'color: gold;',
      permissionsGranted
    )
    const stream = streamRef.current
    // const v = permissionsGranted ? videoRef.current : videoMutedRef.current
    if (!permissionsGranted) {
      console.log('playVideo: permissions are not granted, exit playVideo')
      return null
    }
    const v = videoRef.current
    // const v = videoRef.current
    if (stream && v) {
      if (!videoRecorderRef.current) {
        videoRecorderRef.current = new RVideo(
          stream,
          (v: number) => setDuration(v),
          onNewChunk
        )
      }
      console.log('pause current video')
      v.pause()

      v.srcObject = stream
      if (!permissionsGranted) {
        v.autoplay = false
        v.loop = false
      }
      // v.muted = false
      streamHasBeenSet.current = true
      // v.muted = false
      const playPromise = v.play()
      if (v.paused && playPromise) {
        v.play()
          .then(() => {
            console.log('--> Video Play success')
          })
          .catch(e => console.log('--> Video Play error', e.message))
      } else {
        console.log('Video is not paused')
      }
      if (permissionsGranted) {
        onSessionStarted()
      }
      // startSession(sessionIdRef.current, sdpRef.current)
    } else {
      console.log('playVideo stream or videoref is not ready', stream, v)
    }
  }

  const setVideoElement = async (stream: MediaStream) => {
    console.log('setVideoElement', stream)
    if (!stream) return
    streamRef.current = stream
    console.log('setVideoElement: permissions granted', permissionsGranted)
    playVideo()
    // if (permissionsGranted) {
    //   // playVideo()
    // }
  }

  const newSession = async (
    quality: string
    // avatarName?: string,
    // voiceName?: string
  ) => {
    logEvent('HeyGen: streaming.new call start')
    const response = await fetch(`${SERVER_URL}/v1/streaming.new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey
      },
      body: JSON.stringify({
        quality
        // avatar_name: avatarName,
        // voice: voiceName
      })
    })
    logEvent('HeyGen: streaming.new call end', {
      status: response.status,
      statusText: response.statusText
    })
    if (response.ok) {
      const data = await response.json()
      console.log(data.data)
      logEvent('HeyGen: streaming.new successful', {
        sessionId: data.data.session_id
      })
      return data.data
    } else {
      console.error('Server error')
      logEvent('ERROR: HeyGen: streaming.new fail', {
        code: response.status,
        status: response.statusText
      })
      setThereWasAnError(
        'Server Error. Please ask the staff if the service has been turned on'
      )
    }
  }

  const handleICE = async (sessionId: string, candidate: object) => {
    logEvent('HeyGen: streaming.ice call start')
    const response = await fetch(`${SERVER_URL}/v1/streaming.ice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey
      },
      body: JSON.stringify({ session_id: sessionId, candidate })
    })
    logEvent('HeyGen: streaming.ice call end', {
      sessionId,
      status: response.status,
      statusText: response.statusText
    })
    if (response.status === 500) {
      console.error('Server error')
      logEvent('ERROR: HeyGen streaming.ice failed', {
        sessionId,
        code: response.status,
        status: response.statusText
      })
      throw new Error('Server error')
    } else {
      const data = await response.json()
      logEvent('HeyGen: streaming.ice call - ok')
      return data
    }
  }

  const startSession = async (
    sessionId: string,
    // eslint-disable-next-line no-undef
    sdp: RTCSessionDescriptionInit
  ) => {
    console.log('start session', sessionId, sdp)
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey
      },
      keepalive: true,
      body: JSON.stringify({ session_id: sessionId, sdp })
    }
    console.log('call streaming.start with params')

    setTimeout(() => {
      if (!heygenStartRespondedRef.current) {
        logEvent('HeyGen: streaming.start is pending more than 10 seconds')
      }
    }, 10000)
    logEvent('HeyGen: streaming.start call start', { sessionId })
    const response = await fetch(`${SERVER_URL}/v1/streaming.start`, params)
    logEvent('HeyGen: streaming.start call end', {
      sessionId,
      status: response.status,
      statusText: response.statusText
    })
    console.log('heygen response', response)
    heygenStartRespondedRef.current = true
    if (!response.ok && response.status !== 400) {
      logEvent('ERROR: HeyGen: streaming.start - FAIL', {
        code: response.status,
        status: response.statusText
      })
      console.error('Server error')
      setThereWasAnError(
        'Server Error. Please ask the staff if the service has been turned on'
      )
      throw new Error(
        'HEYGEN Server error: Please ask the staff if the service has been turned on'
      )
    } else if (response.status === 400) {
      const data = await response.json()
      console.log('start session response', data)
      console.log(data.code, data.message)
      logEvent('ERROR: HeyGen: streaming.start - FAIL', data)
    } else if (response.ok) {
      const data = await response.json()
      logEvent('HeyGen: streaming.start - OK', data)
      console.log('start session res', data)
      // onSessionStarted()
      return data.data
    }
  }

  const startAndDisplaySession = async sessionInfo => {
    if (!sessionInfo) {
      console.warn('Please create a connection first')
      setThereWasAnError('Please create a connection first')
      logEvent('HeyGen: cannnot start session, no session info')
      return
    }

    console.log('%cStarting session... please wait', 'color: green')

    // Create and set local SDP description
    logEvent('HeyGen: set local description - start')
    const localDescription = await peerConnectionRef.current.createAnswer()
    await peerConnectionRef.current.setLocalDescription(localDescription)
    logEvent('HeyGen: set local description - end')

    sdpRef.current = localDescription
    // Start session
    console.log('%cSession initialized', 'color: green')
    // if (permissionsGranted) {
    await startSession(sessionInfo.session_id, localDescription)
    // } else {
    //   // sdpRef.current = localDescription
    // }
  }

  const connect = async () => {
    const previewsSessionId = localStorage.getItem('heygenSessionId')
    if (previewsSessionId) {
      console.log('previews session id is found', previewsSessionId)
      await _stopSession(previewsSessionId)
    }
    // call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
    const sessionInfo = await newSession('high')
    const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo

    sessionIdRef.current = sessionInfo.session_id
    localStorage.setItem('heygenSessionId', sessionInfo.session_id)

    // Create a new RTCPeerConnection
    peerConnectionRef.current = new RTCPeerConnection({ iceServers })
    // const formattedIceServers = iceServers.map(server => ({ urls: server }))
    // peerConnectionRef.current.setConfiguration({
    //   iceServers: formattedIceServers
    // })

    // When ICE candidate is available, send to the server
    peerConnectionRef.current.onicecandidate = ({ candidate }) => {
      console.log('Received ICE candidate:', candidate)
      if (candidate) {
        // logEvent('HeyGen received ice candidate')
        handleICE(sessionInfo.session_id, candidate.toJSON())
      }
    }

    // When ICE connection state changes, display the new state
    peerConnectionRef.current.oniceconnectionstatechange = () => {
      logEvent('HeyGen: ICE connection state', {
        state: peerConnectionRef.current.iceConnectionState
      })
      console.log(
        `ICE connection state changed to: ${peerConnectionRef.current.iceConnectionState}`
      )
    }

    peerConnectionRef.current.onconnectionstatechange = (event: Event) => {
      logEvent(
        `HeyGen: connection state changed - ${peerConnectionRef.current.connectionState}`
      )
      console.log('onconnectionstatechange event', event)
      console.log(
        '%conconnectionstatechange',
        'color: gold;',
        peerConnectionRef.current.connectionState
      )
      // if (peerConnectionRef.current.connectionState === 'connected') {
      //   setVideoElement(streamRef.current)
      // }
    }

    peerConnectionRef.current.ondatachannel = event => {
      console.log('ondatachannel-----, event', event)
    }

    // When audio and video streams are received, display them in the video element
    peerConnectionRef.current.ontrack = event => {
      // logEvent('Received the track', { kind: event.track.kind })
      if (event.track.kind === 'audio' || event.track.kind === 'video') {
        // event.track.enabled = false
        if (!streamRef.current) {
          logEvent('HeyGen: media track received', {
            trackKind: event.track.kind
          })
          setVideoElement(event.streams[0])
        }
      }
    }

    // Set server's SDP as remote description
    const remoteDescription = new RTCSessionDescription(serverSdp)
    try {
      await peerConnectionRef.current.setRemoteDescription(remoteDescription)
    } catch (e) {
      console.error('setRemoteDescription error', e)
    }

    console.log('Session creation completed')
    await startAndDisplaySession(sessionInfo)

    // setTimeout(() => startTalk(initialPhrase), 500)
  }

  const _stopSession = async (sessionId: string) => {
    const response = await fetch(`${SERVER_URL}/v1/streaming.stop`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey
      },
      body: JSON.stringify({ session_id: sessionId })
    })
    if (response.status === 500) {
      console.error('Server error')
      // updateStatus(statusElement, "Server Error. Please ask the staff for help");
      throw new Error('Server error')
    } else {
      const data = await response.json()
      return data.data
    }
  }

  const stopSession = async () => {
    const sessionId = sessionIdRef.current
    if (videoRecorderRef.current) {
      videoRecorderRef.current.stop()
    }
    if (sessionId) {
      _stopSession(sessionId)
    }
  }

  const onVoiceDetected = () => {
    const job = jobRef.current
    if (job) {
      console.log('run existing job')
      logEvent('HeyGen: avatar voice detected')
      jobRunTime.current = Date.now()
      job()
    }
    setVoiceDetectorEnabled(false)
  }

  const onNewChunk = (blob: Blob) => {
    const mimeType = videoRecorderRef.current.mimeType
    handleChunk(blob, mimeType, 'avatar')
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative'
      onClick={handleVideoClick}
    >
      {permissionsGranted ? (
        <>
          <video
            key={'heygen_video'}
            id='heygen_video'
            ref={videoRef}
            className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0 bg-blackAlpha-800'
            playsInline
            // muted={isRecording}
            controls={false}
            autoPlay={false}
            muted={false}
            // onPlay={() => console.log('----> video: on play <-----')}
            // onCanPlay={() => {
            //   console.log('onCanPlay')
            //   playVideo()
            // }}
            onAbort={() => console.log('Video: onAbort')}
            onError={e => console.log('Video: onError', get(e, 'message'))}
          />
          <BackgroundRemover key={'heygenVideo'} videoRef={videoRef} />
        </>
      ) : (
        <>
          <video
            key={'muted_video'}
            id='muted_video'
            src='avatar_initial.mp4'
            poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/avatar_initial_bg.png?alt=media&token=3653cda3-365d-4ddc-9e4b-2160bd949f7b'
            ref={videoMutedRef}
            className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0'
            playsInline
            // muted={isRecording}
            // controls
            autoPlay
            loop
            muted
            // onPlay={() => console.log('----> video: on play <-----')}
            // onCanPlay={() => {
            //   console.log('onCanPlay')
            //   playVideo()
            // }}
            onAbort={() => console.log('Muted Video: onAbort')}
            onError={e =>
              console.log('Muted Video: onError ' + get(e, 'message'))
            }
          />
          <BackgroundRemover key={'mutedVideo'} videoRef={videoMutedRef} />
        </>
      )}

      {/* {!permissionsGranted && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-gray-700 w-full h-full' />
      )} */}
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
      {voiceDetectorEnabled && (
        <VoiceDetector
          streamRef={streamRef}
          onVoiceDetected={onVoiceDetected}
          logEvent={logEvent}
        />
      )}
    </div>
  )
}

export default forwardRef(HeyGenConnect)
