import { FC, ReactNode, useMemo } from 'react'

type Props = {
  color?: string
  children?: ReactNode
  onClick?: () => void
  variant?: 'solid' | 'outline'
}

const Button: FC<Props> = ({
  children,
  color = 'teal',
  onClick,
  variant = 'solid'
}) => {
  // let cn = `bg-${color}-500 hover:bg-${color}-600 active:bg-${color}-700`
  // if (variant === 'outline') {
  //   cn = ` bg-white hover:bg-${color}-50 active:bg-${color}-50 border-${color}-600`
  // }

  const colorScheme = useMemo(() => {
    if (variant === 'solid') {
      switch (color) {
        case 'red':
          return 'bg-red-500 hover:bg-red-600 active:bg-red-700 text-white text-xl font-medium'
        case 'teal':
          return 'bg-teal-500 hover:bg-teal-600 active:bg-teal-700 text-white text-xl font-medium'
        case 'green':
          return 'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white text-xl font-medium'
        case 'blue':
          return 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white text-xl font-medium'
        default:
          return 'bg-teal-500 hover:bg-teal-600 active:bg-teal-700 text-white text-xl font-medium'
      }
    } else {
      switch (color) {
        case 'red':
          return 'border-2 border-red-500 text-red-500 text-xl font-medium'
        case 'teal':
          return 'border-2 border-teal-500 text-teal-500 text-xl font-medium'
        case 'green':
          return 'border-2 border-green-500 text-greem-500 text-xl font-medium'
        case 'blue':
          return 'border-2 border-blue-500 text-blue-500 text-xl font-medium'

        default:
          return 'border-2 border-red-500 text-red-500 text-xl font-medium'
      }
    }
  }, [color, variant])

  return (
    <button
      className={'w-full rounded-lg transition-colors h-16 ' + colorScheme}
      onClick={onClick}
    >
      {children}
      {/* <p className='text-white text-[20px] font-semibold'>{children}</p> */}
    </button>
  )
}

export default Button
