import { ReactComponent as FaceFileLogo } from 'shared/assets/logo_whitebg.svg'
import { ReactComponent as ConfusedIcon } from 'shared/assets/bi_confused.svg'
import { FC } from 'react'

export enum IWarningType {
  NOT_FOUND = 'not_found',
  NOT_READY = 'not_ready',
  DELETED = 'deleted',
  UNEXPECTED = 'unexpected'
}

const TITLE = {
  [IWarningType.NOT_FOUND]: "Oops! The form you're looking for does not exist.",
  [IWarningType.NOT_READY]: "Oops! The form you're looking for is not ready",
  [IWarningType.DELETED]: "Oops! The form you're looking for has been deleted",
  [IWarningType.UNEXPECTED]: 'Oops! An unexpected error has occurred'
}

const DESC = {
  [IWarningType.NOT_FOUND]: [
    "It might have been deleted or is no longer shared. We're sorry for this unexpected hitch.",
    'Please contact the sender to share a valid link to an active FaceFile form.'
  ],
  [IWarningType.NOT_READY]: [
    'Its creator may still be working on it.',
    'Please contact the sender to share a valid link to an active FaceFile form.'
  ],
  [IWarningType.DELETED]: [
    'Please contact the sender to share a valid link to an active FaceFile form.'
  ],
  [IWarningType.UNEXPECTED]: []
}

type Props = {
  wType: IWarningType
  message?: string
}

const WarningPage: FC<Props> = ({ wType, message }) => {
  return (
    <div className='w-full h-full flex justify-center '>
      <div className='h-full w-full flex flex-col justify-between flex-1 max-w-md relative'>
        <div className='w-full flex flex-col'>
          <div className='w-full flex items-center justify-center pt-5 pb-9'>
            <FaceFileLogo />
          </div>
          <div className='w-full flex items-center justify-center pt-10 pb-10'>
            <ConfusedIcon />
          </div>
          <div className='w-full font-bold pl-8 pr-8'>
            <p>{TITLE[wType]}</p>
          </div>
          <div className='w-full pt-6 pl-8 pr-8'>
            {wType !== IWarningType.UNEXPECTED
              ? DESC[wType].map((v, i) => (
                  <p key={i} className='text-sm text-blackAlpha-500 pb-4'>
                    {v}
                  </p>
              ))
              : message}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WarningPage
