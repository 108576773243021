import get from 'lodash/get'
import { InteractionContactT } from 'shared/types/model'
import { convert } from 'shared/utils/video2audio'
import { getLocation, LocationT } from 'utils/location'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

// export const subscribeToInteraction = async (
//   interactionId: string,
//   callback: (i: InteractionT.T) => void
// ) => {
//   try {
//     const ref = doc(db, 'linkInteractions', interactionId)
//     const unsubscribe = onSnapshot(
//       ref,
//       sn => {
//         const i = sn.data() as InteractionT.T
//         callback(i)
//       },
//       err => {
//         console.log(`subscribeToInteraction error: ${err.message}`)
//       }
//     )
//     addListener('interaction', unsubscribe)
//   } catch (e) {
//     console.log('subscribeToInteraction error', e)
//   }
// }

export const interactionStart = async (linkId: string) => {
  console.log('interaction start')
  try {
    const pageUrl = new URL(window.location.href)
    const caseId = pageUrl.searchParams.get('c')
    const url = `${process.env.REACT_APP_API_URL}/link/interaction`
    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ linkId, caseId }),
      headers
    })
    const text = await req.text()
    const data = JSON.parse(text)
    return data
  } catch (e) {
    console.warn('interactionStart error', e)
    // Sentry.captureException(e)
    return { message: get(e, 'message'), error: 'unexpected' }
  }
}

export const interactionGet = async (interactionId: string) => {
  console.log('interactionGet start')
  try {
    const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}`
    const req = await fetch(url, {
      method: 'GET',
      headers
    })
    const data = await req.json()
    return data
  } catch (e) {
    console.log('interactionGet error', e)
    // Sentry.captureException(e)
    return { error: 'unexpected', message: get(e, 'message') }
  }
}

// readAsBinaryString - THE METHOD IS DEPRECATED AND DOES NOT WORK ON MOBILE
const blobToBinaryString = async (b: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(b)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = e => reject(e)
  })
}

const blobToBinaryStringNew = async (b: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer)
      const binary = bytes.reduce(
        (acc, byte) => acc + String.fromCharCode(byte),
        ''
      )
      resolve(binary)
    }
    reader.readAsArrayBuffer(b)
    reader.onerror = e => reject(e)
  })
}

const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}

const blobToArrayBuffer = async (b: Blob): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(b)
    reader.onload = () => resolve(reader.result as ArrayBuffer)
    reader.onerror = e => reject(e)
  })
}

export const blobToText = async (b: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(b)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = e => reject(e)
  })
}

export const chunk2Wav = async (b: Blob) => {
  const label = 'chunk2Wav_' + Date.now()
  console.timeLog(label, 'video converted to binary string')
  const targetAudioFormat = 'wav'
  const videoArrayBuffer = await blobToArrayBuffer(b)
  const audio = await convert(videoArrayBuffer, targetAudioFormat)
  console.timeLog(label, 'audio extrated')
  return audio
  // const audiodata = await blobToBinaryString(audio.blob)
  // return audiodata
}

export const deleteAnswer = async (
  interactionId: string,
  questionId: string
) => {
  const url = `${process.env.REACT_APP_API_URL}/interaction/${interactionId}/${questionId}`
  const req = await fetch(url, {
    method: 'DELETE',
    headers
  })
  if (req.status >= 300 || req.status < 200) {
    console.log(req.status)
    const j = await req.json()
    console.log(j)
    return null
  } else {
    const data = await req.json()
    return data
  }
}

export const dbRestartInteraction = async (interactionId: string) => {
  const url = `${process.env.REACT_APP_API_URL}/interaction/${interactionId}/restart`
  const req = await fetch(url, {
    method: 'PATCH',
    headers
  })
  if (req.status >= 300 || req.status < 200) {
    console.log(req.status)
    const j = await req.json()
    console.log(j)
    return null
  } else {
    const data = await req.json()
    return data
  }
}

export const dbSubmitContactForm = async (
  interactionId: string,
  c: InteractionContactT
) => {
  const url = `${process.env.REACT_APP_API_URL}/interaction/${interactionId}/contact`
  const req = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(c)
  })
  if (req.status >= 300 || req.status < 200) {
    console.log(req.status)
    const j = await req.json()
    console.log(j)
    return null
  } else {
    const data = await req.json()
    return data
  }
}

// const transcribe = async (audiodata: Blob) => {
//   console.log('call deepgram')
//   const url = 'https://api.deepgram.com/v1/listen?model=nova'
//   const res = await fetch(url, {
//     method: 'POST',
//     headers: {
//       Authorization: 'Token 12119ffeae40b925228bbf162a1825dc1506ff8d',
//       Accept: 'application/json'
//     },
//     body: audiodata
//   })
//   console.log('deepgram status', res.status)
//   const j = await res.json()
//   console.log('deepgram res', j)
// }

export const sendAnswerText = async (
  interactionId: string,
  textAnswer: string
) => {
  const pageUrl = new URL(window.location.href)
  const caseId = pageUrl.searchParams.get('c')
  console.log('sendAnswerText', textAnswer, 'caseId', caseId)
  const endpoint =
    getLocation() === LocationT.CHATGPT ? 'answerChatGPT' : 'answerText3'
  const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}/${endpoint}`
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      textAnswer,
      caseId
    }),
    headers
    //   Accept: 'application/json'
    //   // ...headers,
    //   // 'Content-Type': 'multipart/form-data'
    // }
  })
  console.timeLog('pr', 'backend response received')
  console.timeEnd('pr')
  const data = await req.json()

  console.log('response', data)
  return data
}

export const sendAnswerScreenshot = async (
  interactionId: string,
  questionId: string,
  screenshotBlob: Blob
) => {
  console.log('send asnwer screenshot', screenshotBlob)
  const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}/${questionId}/screenshot`
  const screenshot = await blobToBinaryStringNew(screenshotBlob)
  // const blob = new Blob([screenshotBlob])
  // const screenshot = new Uint8Array(await blob.arrayBuffer())
  // const formData = new FormData()

  // formData.append('screenshot', screenshot)
  // console.log('formData', formData)
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      screenshot
    }),
    headers
    // headers: {
    //   'Content-Type': 'multipart/form-data'
    // }
  })
  const data = await req.json()

  console.log('sendAnswerScreenshot response', data)
  return data
}

export const sendAnswerScreenshotDeepface = async (
  interactionId: string,
  questionId: string,
  screenshotBlob: Blob
) => {
  console.log('%csend asnwer screenshot', 'color:gold;', screenshotBlob)
  const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}/${questionId}/screenshotDeepface`
  const screenshot = await blobToBase64(screenshotBlob)
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      screenshot
    }),
    headers
  })
  const data = await req.json()

  console.log('%csendAnswerScreenshotDeepface response', 'color:gold;', data)
  return data
}

export const sendAnswerVideo = async (
  interactionId: string,
  questionId: string,
  videoBlob: Blob
) => {
  const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}/${questionId}/video`
  const video = await blobToBinaryString(videoBlob)
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      video
    }),
    headers
  })
  const data = await req.json()

  console.log('response', data)
  return data
}

export const sendAnswer = async (
  interactionId: string,
  b: Blob,
  duration: number
) => {
  console.log('sendAnswer', interactionId, b.size, 'duration', duration)

  // const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}/answer`
  // const formData = new FormData()
  // formData.append('files', b)
  const label = 'pr' + Date.now()
  console.time(label)
  // const videodata = await blobToBinaryString(b)
  // console.timeLog(label, 'video converted to binary string')
  // const targetAudioFormat = 'wav'
  // const videoArrayBuffer = await blobToArrayBuffer(b)
  // console.timeLog(label, 'video converted to array buffer', videoArrayBuffer)
  // const audio = await convert(videoArrayBuffer, targetAudioFormat)
  // console.timeLog(label, 'audio extrated')
  // // const audiodata = await blobToBinaryString(audio.blob)
  // console.timeLog(label, 'audio converted to binary string')

  // await transcribe(b)
  console.timeEnd(label)
  // const req = await fetch(url, {
  //   method: 'POST',
  //   body: JSON.stringify({
  //     audiodata,
  //     // videodata,
  //     duration
  //   }),
  //   headers
  //   //   Accept: 'application/json'
  //   //   // ...headers,
  //   //   // 'Content-Type': 'multipart/form-data'
  //   // }
  // })
  // console.timeLog('pr', 'backend response received')
  // console.timeEnd('pr')
  // const data = await req.json()

  // console.log('response', data)
  // return data
  return null
}

export const submitLocation = async (
  interactionId: string,
  lng: number,
  lat: number
) => {
  const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}/position`
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      lat,
      lng
    }),
    headers
  })
  const data = await req.json()

  console.log('%submitLocation response', 'color:gold;', data)
  return data
}
