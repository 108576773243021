/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useMemo, useRef, useCallback } from 'react'

import {
  interactionStart,
  sendAnswerScreenshot,
  sendAnswerScreenshotDeepface,
  sendAnswerText
} from 'controllers/init'
import get from 'lodash/get'
import { InteractionT } from 'shared/types/facesign'
import { IWarningType } from 'components/WarningPage'
import last from 'lodash/last'
import Landing from 'shared/components/Landing'
// import HeyGenConnect, { IHeyGenConnect } from 'components/HeyGenConnect'

import { LocationT, getLocation } from 'utils/location'
import { WidthDebugT } from 'components/DebugPanelAI'
import sortBy from 'lodash/sortBy'
import { DFRekognitionT } from 'shared/types/deepface'
import Conversation from 'pages/Conversation'
// import DiDConnect, { IdidConnect } from 'components/DidConnect'

const App = () => {
  const [interaction, setInteraction] = useState<InteractionT.T>()
  const [error, setError] = useState<IWarningType | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [landingPassed, setLandingPassed] = useState(false)
  // const didConnectRef = useRef<IdidConnect>(null)
  // const heyGenConnectRef = useRef<IHeyGenConnect>(null)
  const [initialPhrase, setInitialPhrase] = useState('')
  const [initialQuestionId, setInitialQuestionId] = useState('')
  const [duration, setDuration] = useState<number>(0)
  const [widthDebugInfo, setWidthDebugInfo] = useState<WidthDebugT>()
  const [deepfaceRekognition, setDeepfaceRekognition] =
    useState<DFRekognitionT>()
  const [deepfaceIsRunning, setDeepfaceIsRunning] = useState(false)
  const [dgKey, setDgKey] = useState<string>()
  // const [analyzing, setAnalyzing] = useState(false)

  const lastRekognition = useMemo(() => {
    if (interaction && interaction.rekognition) {
      const sortedR = sortBy(interaction.rekognition, r => r.startTime)
      return last(sortedR)
    }
  }, [interaction])

  const imageRekognition = useMemo(() => {
    return interaction?.imageRekognition
  }, [interaction])

  const { step, index, isLast, isFirst } = useMemo(() => {
    const stepIsIncomplete = (s: InteractionT.StepT) => {
      switch (s.sType) {
        case 'question':
          return !(s as InteractionT.QuestionT).answerId
        case 'landing':
          return !landingPassed
        // return !(s as InteractionT.LandingT).buttonPressed
      }
    }
    // console.log('currentQuestion calc, interaction', interaction)
    let res: {
      step?: InteractionT.StepT
      index: number
      isLast: boolean
      isFirst: boolean
    } = { step: undefined, index: 0, isLast: false, isFirst: false }
    if (interaction && interaction.steps.length > 0) {
      // console.log('iterating over questions to find a question withno answer')
      for (let i = 0; i < interaction.steps.length; i++) {
        const s = interaction.steps[i]
        // console.log('check', i, s)
        if (stepIsIncomplete(s)) {
          res = {
            step: s,
            index: i,
            isLast: Number(i) === interaction.steps.length - 1,
            isFirst: Number(i) === 0
          }
          break
        }
      }
    }
    return res
  }, [interaction, landingPassed])

  const findAndSetupInitialPhrase = (int: InteractionT.T) => {
    const lastStep = last(int.steps)
    setInitialPhrase(get(lastStep, 'text', 'Hi, how can I help you?'))
    setInitialQuestionId(get(lastStep, 'id', ''))
  }

  const onInteractionReceived = (i: InteractionT.T) => {
    console.log('on interaction received', i)
    setInteraction(i)
  }

  useEffect(() => {
    const init = (int: InteractionT.T) => {
      findAndSetupInitialPhrase(int)
    }
    const run = async () => {
      // console.log('window.location', window.location)
      const url = new URL(window.location.href)
      // console.log('url', url)
      const linkId = url.searchParams.get('l')
      if (linkId) {
        const res = await interactionStart(linkId)
        console.log('res', res)
        if (res && res.interaction) {
          setInteraction(res.interaction)
          init(res.interaction)
        } else {
          setError(res.error || IWarningType.UNEXPECTED)
          setErrorMessage(res.message)
        }
        if (res && res.dgKey && res.dgKey.key) {
          setDgKey(res.dgKey.key)
        }
      } else {
        setError(IWarningType.NOT_FOUND)
      }
    }
    run()
  }, [])

  const onScreenshot = async (b: Blob) => {
    if (interaction) {
      const res = await sendAnswerScreenshot(
        interaction.id,
        get(step, 'id', Date.now().toString()),
        b
      )
      if (res && res.interaction) {
        setInteraction(res.interaction)
      }
      // if (!deepfaceIsRunning) {
      //   setDeepfaceIsRunning(true)
      //   const res2 = await sendAnswerScreenshotDeepface(
      //     interaction.id,
      //     get(step, 'id', Date.now().toString()),
      //     b
      //   )
      //   if (res2 && res2.rekognition) {
      //     setDeepfaceRekognition(res2.rekognition)
      //   }
      //   setDeepfaceIsRunning(false)
      // } else {
      //   console.log('skiping the screenshot because Deepface is running')
      // }
    }
  }

  // const onAnswerRecorder = async (
  //   questionId: string,
  //   b: Blob,
  //   startedAt: number,
  //   duration: number,
  //   speech: string
  // ) => {
  //   if (interaction) {
  //     const res = await sendAnswerText(interaction.id, speech)
  //     console.log('res', res)
  //     if (res && res.interaction) {
  //       setInteraction(res.interaction)
  //       const lastStep = last(res.interaction.steps)
  //       const nextPhrase = get(
  //         lastStep,
  //         'text',
  //         'Something went wrong in the logic, seems i am stopping working'
  //       )
  //       heyGenConnectRef.current?.say(nextPhrase)
  //       if (getLocation() === LocationT.WIDTHAI) {
  //         const wInfo: WidthDebugT = {
  //           latency: get(res, ['apires', 'server_latency']),
  //           variants: get(res, ['apires', 'results'], [])
  //         }
  //         setWidthDebugInfo(wInfo)
  //       }
  //     } else {
  //       setError(res.error || IWarningType.UNEXPECTED)
  //       setErrorMessage(res.message)
  //     }
  //   }
  // }

  const restartInteraction = async () => {
    // if (heyGenConnectRef.current) {
    //   await heyGenConnectRef.current.stop()
    // }
    window.location.reload()
  }

  const onLandingButtonClick = (n: number) => {
    setLandingPassed(true)
    // if (n === 1) {
    //   restartInteraction()
    // } else {
    //   setLandingPassed(true)
    // }
  }

  const onConversationFinished = () => {
    console.log('onConversationFinished')
  }

  const handleUnloadEvent = () => null

  const renderContent = () => {
    if (interaction && dgKey && landingPassed) {
      return (
        <Conversation
          verificationId={interaction.id}
          initialPhrase={initialPhrase}
          initialQuestionId={initialQuestionId}
          interactionId={interaction.id}
          onConversationFinished={onConversationFinished}
          handleChunk={() => null}
          onScreenshot={onScreenshot}
          initVersion={0}
          isTest={false}
          setDuration={setDuration}
          unloadEvents={{
            handleUnloadEvent: () => null,
            handleBeforeUnloadEvent: () => null
          }}
          dgKey={dgKey}
        />
      )
    } else if (interaction) {
      return (
        <Landing
          landing={step as InteractionT.LandingT}
          onButtonClick={onLandingButtonClick}
        />
      )
    }
    // if (step) {
    //   if (landingPassed) {
    //     return (
    //       <HeyGenConnect
    //         onAnswerRecorder={onAnswerRecorder}
    //         ref={heyGenConnectRef}
    //         initialPhrase={initialPhrase}
    //         restartInteraction={restartInteraction}
    //         widthDebugInfo={widthDebugInfo}
    //         lastRekognition={lastRekognition}
    //         imageRekognition={imageRekognition}
    //         onScreenshot={onScreenshot}
    //         deepfaceRekognition={deepfaceRekognition}
    //       />
    //     )
    //   } else {
    //     return (
    //       <Landing
    //         landing={step as InteractionT.LandingT}
    //         onButtonClick={onLandingButtonClick}
    //       />
    //     )
    //   }
    // }
  }

  return (
    <div className='w-full h-full relative flex justify-center items-center'>
      {renderContent()}
      {/* {renderAnalizing()} */}
      {/* <VideoPreloader data={videoPreloadData} /> */}
    </div>
  )
}

export default App
