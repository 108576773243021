import { FC } from 'react'
import { InteractionT } from 'shared/types/facesign'
import facefileLogo from 'shared/assets/logo_whitebg.svg'
import Button from 'shared/components/Button'
// import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  landing: InteractionT.LandingT
  onButtonClick: (n: number) => void
}

const Landing: FC<Props> = ({ landing, onButtonClick }) => {
  const renderImage = () => {
    if (landing.branding) {
      if (landing.branding.fit === 'cover') {
        return (
          <img
            src={landing.branding.url}
            className='h-full  w-full object-cover'
          />
        )
      } else {
        return <img src={landing.branding.url} />
      }
    } else {
      return <img src={facefileLogo} />
    }
  }

  const renderPrimaryHeading = () => {
    return (
      <p className='text-xl text-gray-800 font-bold'>
        {landing.primaryHeading}
      </p>
    )
  }

  const renderLocationSubheading = () => {
    if (landing.locationSubheading) {
      return (
        <p className='text-xs text-gray-500 italic mt-[10px]'>
          {landing.locationSubheading}
        </p>
      )
    }
  }

  const renderCTAText = () => {
    return (
      <p className='text-md text-blackAlpha-800 mt-[10px]'>{landing.ctaText}</p>
    )
  }

  const renderTerms = () => {
    if (landing.termsAndConditionsUrl) {
      return (
        <div className='mt-[15px]'>
          <a
            href={landing.termsAndConditionsUrl}
            target='_blank'
            className='text-blue-600 text-sm '
          >
            Terms and conditions{' '}
            <span className='pl-1'>
              {/* <FontAwesomeIcon icon={faExternalLink} color='blue-600' /> */}
            </span>
          </a>
        </div>
      )
    }
  }

  const renderButtons = () => {
    // const color = landing.actionButton.color || 'green'
    // const color2 = landing.actionButton2.color || 'green'
    return (
      <>
        <div className='pt-12'>
          <Button
            color={landing.actionButton.color}
            // color='orange'
            variant={landing.actionButton.variant}
            onClick={() => onButtonClick(0)}
          >
            {landing.actionButton.label}
          </Button>
        </div>
        <div className='pt-[10px]'>
          <Button
            color={landing.actionButton2.color}
            variant={landing.actionButton2.variant}
            onClick={() => onButtonClick(1)}
          >
            {landing.actionButton2.label}
          </Button>
        </div>
      </>
    )
  }

  const renderPoweredBy = () => {
    return (
      <div className='flex flex-row justify-center w-full'>
        <div className='flex flex-row items-center'>
          <span className='text-xs text-blackAlpha-700 font-medium'>
            Powered by
          </span>
          <img className='h-10' src={facefileLogo} />
        </div>
      </div>
    )
  }

  return (
    <div className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative'>
      <div>
        <div className='w-full h-28 bg-cover relative flex justify-center items-center relative shrink-0'>
          {renderImage()}
          {/* {onClose && (
            <button
              onClick={() => onClose()}
              className='absolute top-3 left-3 text-[20px] w-8 h-8 rounded-md border bg-whiteAlpha-500 border-gray-600'
            >
              <FontAwesomeIcon icon={faChevronsRight} color='#193154' />
            </button>
          )} */}
        </div>
        <div className='p-6'>
          {renderPrimaryHeading()}
          {renderLocationSubheading()}
          {renderCTAText()}
          {renderTerms()}
          {renderButtons()}
        </div>
        {/* <div className='w-full h-auto p-4'>{renderContent()}</div> */}
      </div>
      {renderPoweredBy()}

      {/* <div className='px-4 pb-2 text-gray-500 text-sm bg-yellow h-full flex-col flex justify-end'>
        {!showContactForm && howItWorks}
        {!showContactForm && welcome && welcome.name && (
          <WelcomeContacts welcome={welcome} />
        )}
        {renderButton()}
      </div>
      {renderResume()} */}
    </div>
  )
}

export default Landing
