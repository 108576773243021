import { FC, useRef, useState, useEffect } from 'react'
import get from 'lodash/get'
import now from 'lodash/now'
import isNil from 'lodash/isNil'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
// import { ReactComponent as IconVolume } from 'shared/assets/iconVolume.svg'
// import BackgroundRemover from 'shared/components/BackgroundRemover'
import { DotLottiePlayer } from '@dotlottie/react-player'
import '@dotlottie/react-player/dist/index.css'
import { stringSimilarity } from 'string-similarity-js'

import { logEvent, submitReplay } from 'controllers/main'
import HeyGenConnect, {
  IHeyGenConnect
} from 'shared/components/HeyGenConnectV1'
import Recorder, { IRecorder } from 'shared/components/Recorder'
import RecorderDummy from 'shared/components/RecorderDummy'
import PageContainer from 'shared/components/PageContainer'
import {
  ControlT,
  PermissionErrorType,
  InteractionT
} from 'shared/types/facesign'
import EndConversation from 'shared/components/EndConversation'
import ControlPanel, { IControlPanel } from 'shared/components/ControlPanel'
// import { captureThumb } from 'shared/components/recorder/getVideoInfo'
import FixPermission from 'shared/components/FixPermission'
import PermissionsHandler from 'shared/components/PermissionsHandler'
import PACKAGE from '../../package.json'
import isEmpty from 'lodash/isEmpty'

type Props = {
  verificationId: string | null
  interactionId: string
  initialPhrase: string
  initialQuestionId: string
  heygenV2?: boolean
  onConversationFinished: () => void
  handleChunk: (
    videoBlob: Blob,
    mimeType: string,
    role: 'user' | 'avatar'
  ) => void
  onScreenshot?: (b: Blob) => void
  initVersion: 0 | 1
  isTest: boolean
  setDuration: (v: number) => void
  unloadEvents: {
    handleBeforeUnloadEvent: (e: BeforeUnloadEvent) => void
    handleUnloadEvent: () => void
  }
  dgKey: string
}

const Conversation: FC<Props> = ({
  verificationId,
  interactionId,
  initialPhrase,
  initialQuestionId,
  onConversationFinished,
  handleChunk,
  onScreenshot,
  initVersion,
  isTest,
  setDuration,
  unloadEvents,
  dgKey
}) => {
  const startTimeRef = useRef<number>(Date.now())
  const recorderRef = useRef<IRecorder>(null)
  const heyGenRef = useRef<IHeyGenConnect>(null)
  const currentQuestionId = useRef<string>(initialQuestionId)
  const currentHGLatency = useRef<number>(0)
  const cameraVideoRef = useRef<HTMLVideoElement>(null)
  const [isRecording, _setIsRecording] = useState(false)
  const isRecordingRef = useRef(false)
  const isOverRef = useRef(false)
  // const introVideoRef = useRef<HTMLVideoElement>(null)
  const userPhrasesBufferRef = useRef<string[]>([])
  const [orientation, setOrientation] = useState<'portrait' | 'landscape' | ''>(
    ''
  )
  const [controls, setControls] = useState<ControlT>({
    camera: isTest,
    mic: isTest
  })
  const controlsRef = useRef<ControlT>({ camera: false, mic: false })
  const [thereWasAnError, setThereWasAnError] = useState<string>()
  const [permissionsError, setPermissionsError] =
    useState<PermissionErrorType | null>(null)
  const streamRef = useRef<MediaStream | null>(null)
  const [closed, setClosed] = useState<boolean>(false)
  const [permissionsChecked, setPermissionsChecked] = useState<boolean>(false)
  const controlPanelRef = useRef<IControlPanel>(null)
  const screenshotTimer = useRef<number>()
  const stepsRef = useRef<Array<InteractionT.QuestionT | InteractionT.ReplayT>>(
    []
  )
  const [steps, _setSteps] = useState<
    Array<InteractionT.QuestionT | InteractionT.ReplayT>
  >([])
  const [userName, setUserName] = useState<string | null>(null)
  const [checkingPermissions, setCheckingPermissions] = useState(false)
  const [controlPanelVisible, setControlPanelVisible] = useState(
    initVersion === 1
  )

  const setSteps = (
    s: Array<InteractionT.QuestionT | InteractionT.ReplayT>
  ) => {
    _setSteps(s)
    stepsRef.current = s
  }

  const setIsRecording = (v: boolean) => {
    isRecordingRef.current = v
    _setIsRecording(v)
  }

  useEffect(() => {
    // @ts-ignore
    window.fireTestEvent && window.fireTestEvent('ev_conversation_started')
    function handleOrientationChange (event: { matches: any; media: any }) {
      const { matches } = event
      setOrientation(matches ? 'portrait' : 'landscape')
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)')
    setOrientation(mediaQueryPortrait.matches ? 'portrait' : 'landscape')

    mediaQueryPortrait.addEventListener('change', handleOrientationChange)

    logEvent(interactionId, 'conversation_start')

    return () => {
      mediaQueryPortrait.removeEventListener('change', handleOrientationChange)
    }
  }, [])

  useEffect(() => {
    if (permissionsChecked) {
      startTimeRef.current = Date.now()
    }
  }, [permissionsChecked])

  useEffect(() => {
    const stream = streamRef.current as MediaStream
    if (stream) {
      const audioTrack = stream
        .getTracks()
        .find(track => track.kind === 'audio')
      if (audioTrack) {
        audioTrack.enabled = controls.mic
      }
      const videoTrack = stream
        .getTracks()
        .find(track => track.kind === 'video')
      if (videoTrack) {
        videoTrack.enabled = controls.camera
      }
    }
  }, [controls])

  const onHeyGenSessionStarted = () => {
    console.log('%conHeyGenSessionStarted', 'color: red;')
    console.log('camera and mic are enabled, saying initial phrase!!!!!!!')
    const newSteps = [
      ...stepsRef.current,
      generateStep(initialPhrase, InteractionT.StepTypeT.QUESTION)
    ]
    setSteps(newSteps)
    const heygenLatency = Date.now() - startTimeRef.current
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_heygen_started', { latency: heygenLatency })
    }
    setTimeout(() => heyGenRef.current?.say(initialPhrase), 2000)

    const { handleBeforeUnloadEvent, handleUnloadEvent } = unloadEvents
    console.log('added event listeners')
    window.addEventListener('beforeunload', handleBeforeUnloadEvent)
    window.addEventListener('unload', handleUnloadEvent)
  }

  const generateStep = (
    phrase: string,
    sType: InteractionT.StepTypeT.QUESTION | InteractionT.StepTypeT.REPLAY
  ) => ({
    createdAt: now(),
    text: phrase,
    sType,
    id: now().toString()
  })

  const checkMediaPermissions = async () => {
    if (isTest) {
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      return
    }
    try {
      logEvent(interactionId, 'permissions_requested')
      setCheckingPermissions(true)
      await requestMediaPermissions()
      logEvent(interactionId, 'permissions_granted')
      setCheckingPermissions(false)
      console.log('no error with permissions')
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      heyGenRef.current?.play()
      controlsRef.current = { mic: true, camera: true }
      setControls({ mic: true, camera: true })
    } catch (error: any) {
      console.error('MediaOnboardingDialog: ', error)
      logEvent(interactionId, 'permissions_are_NOT_granted', {
        reason: error.type
      })
      if (error.type === MediaPermissionsErrorType.Generic) {
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.SystemPermissionDenied
      ) {
        // user denied permission
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.UserPermissionDenied
      ) {
        // browser doesn't have access to devices
        setPermissionsError(PermissionErrorType.userDenied)
      } else if (
        error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        setPermissionsError(PermissionErrorType.trackError)
      }
    }
  }

  useEffect(() => {
    const run = async () => {
      if (onScreenshot && permissionsChecked && !isTest) {
        screenshotTimer.current = window.setInterval(takeScreenshot, 10000)
        takeScreenshot()
      }
    }
    run()
    return () => {
      if (screenshotTimer.current) {
        window.clearInterval(screenshotTimer.current)
      }
    }
  }, [permissionsChecked])

  const onAvatarPlayingFinished = (latency: number) => {
    console.log('%conAvatarPlayingFinished', 'color: red;', isOverRef.current)
    currentHGLatency.current = latency
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent(`ev_avatar_ended_talking_${steps.length}`, {
        latency,
        isOver: isOverRef.current
      })
    }
    if (isOverRef.current) {
      recorderRef.current?.stop()
      onConversationFinished()
    } else {
      setIsRecording(true)
      recorderRef.current?.start()
    }
  }

  const isSimilarToAvatarPhrase = (speech: string) => {
    const revSteps = [...stepsRef.current].reverse()
    const lastResponse = revSteps.find(
      s => s.sType === InteractionT.StepTypeT.QUESTION
    )
    if (!lastResponse) {
      console.error('no last response')
      return false
    }
    const ar = lastResponse.text
      .replace(/([.?!])\s*(?=[A-Z])/g, '$1|')
      .split('|')
    ar.push(lastResponse.text)
    for (const at of ar) {
      const similarity = stringSimilarity(at, speech)
      console.log('similarity', similarity, at)
      if (similarity > 0.5) {
        console.log('THE PHRASE IS DETECTED AS AVATAR PHRASE')
        logEvent(interactionId, 'SIMILARITY', {
          avatarPhrase: at,
          speech,
          similarity
        })
        return true
      }
    }
    return false
  }

  const onUserPhrase = async (speech: string, dgLatency: number) => {
    console.log('onUserPhrase', speech, dgLatency)
    if (isSimilarToAvatarPhrase(speech)) {
      console.log(
        '%cIGNORING THE PHRASE IT SIMILAR TO THE LATEST AVATAR PHRASE',
        'color: red',
        speech
      )
      logEvent(
        interactionId,
        'IGNORE: the phrase is similar to avatar phrases, we ignore it',
        {
          phrase: speech
        }
      )
      return
    }
    console.log(
      '%cTHE PHRASE IS PASSED',
      'color: green',
      speech,
      'isRecording',
      isRecordingRef.current
    )
    logEvent(interactionId, 'PROCESS: the phrase is similar to User phrase', {
      phrase: speech
    })
    if (!isRecordingRef.current) {
      userPhrasesBufferRef.current.push(speech)
      console.log('%cTHE PHRASE IS MOVED TO BUFFER', 'color: yellow', speech)
      logEvent(
        interactionId,
        'User is not in focus - saving the phrase into a buffer for the future',
        {
          phrase: speech
        }
      )
      return
    }

    const speechWithBuffer = isEmpty(userPhrasesBufferRef.current)
      ? speech
      : userPhrasesBufferRef.current.join(' ') + ' ' + speech
    const digits = speechWithBuffer.match(/\d/g)
    if (digits && digits.length >= 2 && digits.length < 4) {
      userPhrasesBufferRef.current.push(speech)
      console.log(
        '%cTHE PHRASE CONTAINS DIGITS -> to buffer',
        'color: yellow',
        digits
      )
      logEvent(
        interactionId,
        'User said a couple of digits, looks like he is saying the code -> continuing listening',
        {
          phrase: speech,
          digits
        }
      )
      return
    }

    if (!isEmpty(userPhrasesBufferRef.current)) {
      console.log(
        'Buffer is not empty, joinging the buffer with the current user speech'
      )
      logEvent(
        interactionId,
        'Buffer is not empty, joinging the buffer with the current user speech',
        {
          phrase: speech,
          buffer: userPhrasesBufferRef.current
        }
      )
      speech = userPhrasesBufferRef.current.join(' ') + ' ' + speech

      console.log('Speech with buffer', speech)
      logEvent(interactionId, 'Speech with buffer', {
        phrase: speech
      })
    }

    userPhrasesBufferRef.current = []
    const newSteps = [
      ...stepsRef.current,
      generateStep(speech, InteractionT.StepTypeT.REPLAY)
    ]

    setSteps(newSteps)
    setIsRecording(false)
    try {
      const hgLatency = {
        qId: currentQuestionId.current,
        value: currentHGLatency.current
      }
      const res = await submitReplay(
        interactionId,
        speech,
        hgLatency,
        dgLatency
      )
      console.log('submit replay res', res)
      const over = get(res, 'isOver', false)
      isOverRef.current = over
      console.log('set is over', over)
      if (res && res.phrase) {
        setSteps([
          ...stepsRef.current,
          generateStep(res.phrase, InteractionT.StepTypeT.QUESTION)
        ])
        currentQuestionId.current = res.questionId
        heyGenRef.current?.say(res.phrase)
        if (res.userName && isNil(userName)) {
          setUserName(res.userName)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleControlsChange = (v: ControlT) => {
    console.log('handleControlsChange', v)
    logEvent(interactionId, 'mic/cam_click', v)
    if (!permissionsChecked) {
      checkMediaPermissions()
    }
    controlsRef.current = v
    setControls(v)
  }

  const takeScreenshot = async () => {
    // if (cameraVideoRef.current && onScreenshot) {
    //   const screenshotBlob = await captureThumb(cameraVideoRef.current)
    //   if (screenshotBlob) {
    //     // logEvent(interactionId, 'screenshot_captured')
    //     onScreenshot(screenshotBlob)
    //   } else {
    //     // logEvent(interactionId, 'screenshot_capture_error', {
    //     //   reason: 'no screenshotBlob'
    //     // })
    //   }
    // } else {
    //   // logEvent(interactionId, 'screenshot_capture_error', {
    //   //   reason: 'camera is not initialized'
    //   // })
    // }
  }

  const onSpeech = (v: string[]) => {
    console.log('Conversation onSpeech', v)
    // FIXME: pass current trnscript to controlPanel
    // controlPanelRef.current?.setSpeech(v)
  }

  const handleClose = () => {
    setClosed(true)
    const { handleBeforeUnloadEvent, handleUnloadEvent } = unloadEvents
    handleUnloadEvent()
    window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
    window.removeEventListener('unload', handleUnloadEvent)
  }

  const handleVideoClick = () => {
    if (orientation === 'portrait' && !permissionsError && permissionsChecked) {
      controlPanelRef?.current?.click()
    }
  }

  if (closed) {
    return <EndConversation />
  }

  if (verificationId && permissionsError) {
    return (
      <PermissionsHandler
        permissionsError={permissionsError}
        logEvent={(eventType: InteractionT.LogTypeT, data?: object) =>
          logEvent(interactionId, eventType, data)
        }
        verificationId={verificationId}
      />
    )
  }

  return (
    <PageContainer version={PACKAGE.version}>
      <HeyGenConnect
        ref={heyGenRef}
        onAvatarPlayingFinished={onAvatarPlayingFinished}
        thereWasAnError={thereWasAnError}
        setThereWasAnError={setThereWasAnError}
        onSessionStarted={onHeyGenSessionStarted}
        isRecording={isRecording}
        permissionsGranted={permissionsChecked}
        handleChunk={handleChunk}
        setDuration={setDuration}
        logEvent={(eventType: InteractionT.LogTypeT, data?: object) =>
          logEvent(interactionId, eventType, data)
        }
        handleVideoClick={handleVideoClick}
      />
      {isTest && (
        <RecorderDummy
          ref={recorderRef}
          onPhrase={onUserPhrase}
          isActive={isRecording}
          onSpeech={onSpeech}
          cameraVideoRef={cameraVideoRef}
          controls={controls}
          setControls={handleControlsChange}
          setThereWasAnError={setThereWasAnError}
          permissionsError={permissionsError}
          streamRef={streamRef}
          logEvent={(eventType: InteractionT.LogTypeT, data?: object) =>
            logEvent(interactionId, eventType, data)
          }
        />
      )}
      {controlPanelVisible && !isTest && (
        <div
          className={
            'flex-1 bg-slate-100 rounded-md absolute top-[3rem] left-5 w-28 h-44'
          }
        >
          <Recorder
            ref={recorderRef}
            handleChunk={handleChunk}
            onPhrase={onUserPhrase}
            isActive={isRecording}
            onSpeech={onSpeech}
            cameraVideoRef={cameraVideoRef}
            controls={controls}
            setControls={handleControlsChange}
            setThereWasAnError={setThereWasAnError}
            permissionsError={permissionsError}
            streamRef={streamRef}
            logEvent={(eventType: InteractionT.LogTypeT, data?: object) =>
              logEvent(interactionId, eventType, data)
            }
            interactionId={interactionId}
            dgKey={dgKey}
          />
        </div>
      )}

      {permissionsError === PermissionErrorType.userDenied && <FixPermission />}
      {controlPanelVisible && (
        <ControlPanel
          ref={controlPanelRef}
          permissionsError={permissionsError}
          controls={controls}
          setControls={handleControlsChange}
          handleClose={handleClose}
          permissionsChecked={permissionsChecked}
          steps={steps}
          userName={userName}
          isRecording={isRecording}
          orientation={orientation}
        />
      )}
      {!controlPanelVisible && initVersion === 0 && !permissionsChecked && (
        <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full flex items-center justify-center'>
          {/* {!checkingPermissions && (
            <>
              <video
                src='avatar_initial.mp4'
                className='w-full h-full object-cover absolute'
                poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/avatar_initial_bg.png?alt=media&token=3653cda3-365d-4ddc-9e4b-2160bd949f7b'
                muted
                autoPlay
                loop
                playsInline
                ref={introVideoRef}
              />
              <BackgroundRemover videoRef={introVideoRef} />
            </>
          )} */}
          <div className='z-10 absolute top-20 left-4 text-gray-300'>
            <div>
              <div style={{ height: '50px', width: '50px' }}>
                <DotLottiePlayer
                  src='https://lottie.host/1ba91606-15d0-4f27-9f17-095c58dfb5ac/IivUjwcaOY.json'
                  autoplay
                  loop
                  background='transparent'
                  speed={1}
                  style={{ width: '100%', height: '100%' }}
                  direction={1}
                />
              </div>
            </div>
            <span className='text-[10px] pt-1'>
              <i>Turn your volume up</i>
            </span>
          </div>
          {checkingPermissions && (
            <div className='z-10 text-[24px] text-white font-semibold flex flex-col items-center'>
              <span className='drop-shadow-[1px_1px_1px_rgba(0,0,0,1)]'>
                <i>Initializing</i>
              </span>
              <div className='h-1 w-[187px] bg-white mt-[23px]'>
                <div className='h-1 w-[106px] bg-teal-600' />
              </div>
            </div>
          )}

          {!checkingPermissions && (
            <div className='z-10 w-[300px] flex flex-col items-center'>
              <span className='font-semibold text-[24px] text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] text-center leading-8'>
                You're about to chat
                <br />
                with a digital assistant.
              </span>
              <div className='w-full bg-white mt-10 rounded-xl flex flex-col items-center pt-7 pb-4'>
                <span className='text-[20px] font-semibold text-center leading-7'>
                  Allow FaceSign to use your
                  <br />
                  camera and microphone?
                </span>
                <button
                  className='h-16 bg-blue-400 text-white font-medium text-[16px] w-[260px] mt-7 rounded-[4px]'
                  onClick={() =>
                    handleControlsChange({ mic: true, camera: true })
                  }
                >
                  Allow
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </PageContainer>
  )
}

export default Conversation
