import { getLocation, LocationT } from 'utils/location'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export const submitReplay = async (
  interactionId: string,
  textAnswer: string,
  hgLatency: { qId: string; value: number },
  dgLatency: number
) => {
  const pageUrl = new URL(window.location.href)
  const caseId = pageUrl.searchParams.get('c')
  console.log(
    'sendAnswerText',
    textAnswer,
    'caseId',
    caseId,
    'hgLatency',
    hgLatency,
    'dgLatency',
    dgLatency
  )
  const endpoint =
    getLocation() === LocationT.CHATGPT ? 'answerChatGPT' : 'answerText3'
  const url = `${process.env.REACT_APP_API_URL}/link/interaction/${interactionId}/${endpoint}`
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      textAnswer,
      caseId
    }),
    headers
    //   Accept: 'application/json'
    //   // ...headers,
    //   // 'Content-Type': 'multipart/form-data'
    // }
  })
  console.timeLog('pr', 'backend response received')
  console.timeEnd('pr')
  const data = await req.json()

  console.log('response', data)
  return data
}

export const logEvent = async (
  interactionId: string,
  logType: string,
  data?: object
) => {
  // const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/log`
  // const l: InteractionT.LogT = {
  //   type: logType,
  //   location: 'frontend',
  //   data
  // }
  // const req = await fetch(url, {
  //   method: 'POST',
  //   body: JSON.stringify({ event: l }),
  //   headers
  // })
  // const j = await req.json()

  console.log('LOG:', logType, data, interactionId)
  return null
}
