// import {
//   LiveTranscription,
//   LiveTranscriptionEvents
// } from 'shared/controllers/liveTranscription'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import { createClient, LiveClient } from '@deepgram/sdk'

const MILLISECOND = 1000
const PREFERRED_SAMPLE_RATE = 16000
const PACKET_DURATION = 500
const BUFFER_SIZE = 2048
const STEREO_FLOAT_TO_MONO_INT16 = 0x10000 / 4 - 1

class RAudio {
  private _stream: MediaStream
  private _blobs: Blob[] = []
  private _dg?: LiveClient
  private _speach: [string, string] = ['', '']
  private _onSpeach?: (s: [string, string]) => void
  private _onFinalDetected?: (transcript: [string, string]) => void
  private _logEvent?: (eventType: string, data?: object) => void
  private _interactionId: string
  private _source?: MediaStreamAudioSourceNode
  private _recorderNode?: AudioWorkletNode
  private _audioContext?: AudioContext
  private _dgKey?: string
  private _dgConnected: boolean = false

  constructor (
    stream: MediaStream,
    onSpeach: (s: [string, string]) => void,
    onFinalDetected: (transcript: [string, string]) => void,
    logEvent: (eventType: string, data?: object) => void,
    interactionId: string,
    dgKey: string
  ) {
    logEvent('RAUDIO: constructor')
    this._stream = stream
    this._onSpeach = onSpeach
    this._logEvent = logEvent
    this._onFinalDetected = onFinalDetected
    this._interactionId = interactionId
    this._dgKey = dgKey
    this.start()
    // this._transcriberConnect(dgKey)
  }

  get speach () {
    return this._speach
  }

  private _resetSpeech = () => {
    console.log('reset speech')
    this._speach = ['', '']
  }

  private _transcriberConnect = (sampleRate: number) => {
    const _deepgram = createClient(this._dgKey)
    this._logEvent('Deepgram client created')
    const dgParams = {
      model: 'nova-2',
      smart_format: true,
      // endpointing: 1000,
      no_delay: true,
      interim_results: true,
      utterance_end_ms: 1000,
      diarize: true,
      punctuate: true,
      sample_rate: sampleRate,
      channels: 1,
      encoding: 'linear16',
      language: 'en-US',
      // utterances: true,
      numerals: true
    }
    this._dg = _deepgram.listen.live(dgParams)
    this._logEvent('Deepgram listen.live', dgParams)

    this._dg.on('open', async () => {
      this._logEvent('Deepgram connected')
      this._dgConnected = true
      console.log('client: connected to websocket')

      this._dg.on('Results', data => {
        console.log('DG RESULTS:', data)
        // const transcript = data.channel.alternatives[0].transcript
        if (data.type === 'Results') {
          this._onSocketMessage(data)
        } else if (data.type === 'UtteranceEnd') {
          this._onUtteranceEnd()
        }
      })

      this._dg.on('error', e => console.error('DG ERROR:', e))

      this._dg.on('warning', e => console.warn('DG WARNING:', e))

      this._dg.on('Metadata', e => console.log('DG METADATA:', e))

      this._dg.on('close', e => {
        console.log('DG CLOSE:', e)
        this._dgConnected = false
      })
    })

    // this._logEvent('RAUDIO: connecting to Deepgram')
    // this._dg = new LiveTranscription(this._logEvent, dgKey)
    // // deepgramSocketRef.current = dg
    // this._dg.addListener(LiveTranscriptionEvents.Open, () => {
    //   console.log('ON SOCKET OPEN')
    //   this._logEvent('RAUDIO: transcriber is connected')
    // })
    // this._dg.addListener(LiveTranscriptionEvents.DeepgramConnected, () => {
    //   // console.log('ON SOCKET OPEN')
    //   this._logEvent('RAUDIO: Deepgram is connected')
    // })
    // this._dg.addListener(LiveTranscriptionEvents.Close, () => {
    //   console.log('ON SOCKET CLOSE')
    //   this._logEvent('RAUDIO: transcriber is DISCONNECTED')
    // })
    // this._dg.addListener(LiveTranscriptionEvents.Error, e => {
    //   console.log('ON SOCKET ERROR', e)
    //   this._logEvent('ERROR | RAUDIO: transcriber connection error', {
    //     error: e
    //   })
    // })
    // this._dg.addListener(
    //   LiveTranscriptionEvents.TranscriptReceived,
    //   this._onSocketMessage
    // )
    // this._dg.addListener(
    //   LiveTranscriptionEvents.UtteranceEnd,
    //   this._onUtteranceEnd
    // )
  }

  private _onUtteranceEnd = () => {
    console.log('ON UTTERANCE END')
    this._logEvent('RAUDIO: UTTERANCE END received from Deepgram')
    if (this._speach[0] !== '' || this._speach[1] !== '') {
      const s = [...this._speach] as [string, string]
      this._resetSpeech()
      this._onFinalDetected && this._onFinalDetected(s)
      // this._dg.stop()
    }
  }

  private _onSocketMessage = (received: any) => {
    // console.log('socket message', message)
    // console.log('socket message:', received)
    const t = get(received, ['channel', 'alternatives', 0, 'transcript'])
    const isFinal = get(received, 'is_final', false)
    const speachFinal = get(received, 'speech_final', false)
    const transcripts = [[], []]
    const words = get(received, ['channel', 'alternatives', 0, 'words'])
    forEach(words, w => {
      if (w.speaker === 0) {
        transcripts[0].push(w.punctuated_word)
      } else if (w.speaker === 1) {
        transcripts[1].push(w.punctuated_word)
      }
    })

    const speaker0 = transcripts[0].join(' ').trim()
    const speaker1 = transcripts[1].join(' ').trim()

    console.log('%cspeaker 0', 'color:yellow', speaker0)
    console.log('%cspeaker 1', 'color:yellow', speaker1)

    if (t !== '') {
      this._logEvent('transcript received from Deepgram', {
        // message: t,
        speaker0,
        speaker1,
        isFinal,
        speachFinal
      })
    }

    if (isFinal) {
      this._speach = [speaker0, speaker1]
    }
    // if (isFinal && t && t !== '') {
    // if (speaker0 !== '') {
    //   this._speach[0] = this._speach[0] + ' ' + speaker0
    // }
    // if (speaker1 !== '') {
    //   this._speach[1] = this._speach[1] + ' ' + speaker1
    // }

    // } else {
    //   this._onSpeach &&
    //     this._onSpeach([
    //       this._speach[0] + ' ' + speaker0,
    //       this._speach[1] + ' ' + speaker1
    //     ])
    // }

    if (isFinal && speachFinal && (speaker0 !== '' || speaker1 !== '')) {
      // this._speach = [speaker0, speaker1]
      // this._onSpeach && this._onSpeach(this._speach)
      // deepgramSocketRef.current?.send(JSON.stringify({ type: 'CloseStream' }))
      console.log('FINAL PHRASE IS DETECTED')
      this._resetSpeech()
      this._onFinalDetected && this._onFinalDetected([speaker0, speaker1])
      // this._dg.stop()
      // handleStopRecording()
    }
    // this._onSpeach && this._onSpeach(this._speach)
  }

  stop = () => {
    this._logEvent('RAUDIO: STOP')
    console.log('Raudio STOP')
    this._dg && this._dg.finish()
    this._source && this._source.disconnect()
    this._recorderNode && this._recorderNode.disconnect()
    this._audioContext &&
      this._audioContext.state !== 'closed' &&
      this._audioContext.close()
  }

  start = async () => {
    this._speach = ['', '']
    this._audioContext = new AudioContext({ sampleRate: PREFERRED_SAMPLE_RATE })
    try {
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    } catch (e) {
      this._audioContext = new AudioContext() // some browsers can’t return arbitrary audio sample rate, such as firefox
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    }
    const packetSamples =
      (this._audioContext.sampleRate * PACKET_DURATION) / MILLISECOND
    let port: any
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      const recorder = this._audioContext.createScriptProcessor(BUFFER_SIZE)
      this._source.connect(recorder)
      port = {}
      let queueLength = 0
      const start = Date.now()
      let num = 0
      const queue = new Int16Array(packetSamples)
      recorder.onaudioprocess = function (e) {
        if (port.onmessage) {
          const left = e.inputBuffer.getChannelData(0)
          const right = e.inputBuffer.getChannelData(1) || left
          if (left && right) {
            for (let i = 0; i < left.length; i++) {
              queue[queueLength++] =
                (left[i] + right[i]) * STEREO_FLOAT_TO_MONO_INT16
              if (queueLength === queue.length) {
                queueLength = 0
                port.onmessage({
                  data: {
                    data: queue,
                    log: [Date.now(), Date.now() - start, ++num]
                  }
                })
              }
            }
          }
        }
      }
      recorder.connect(this._audioContext.destination)
    } else {
      const blob = new Blob(
        [
          `
registerProcessor("pcm-processor", class extends AudioWorkletProcessor {
  constructor(options) {
    super();
    this.start=Date.now();
	this.num=0;
	this.queue=new Int16Array(${packetSamples});
	this.queueLength=0;
  }
  process(input) {
    const left = input[0][0];
    const right = input[0][1]||left;
	if(left && right){
		for(let i=0;i<left.length;i++){
			this.queue[this.queueLength++]=(left[i]+right[i])*${STEREO_FLOAT_TO_MONO_INT16};
			if(this.queueLength===this.queue.length){
					this.queueLength=0;
					this.port.postMessage({data:this.queue,log:[Date.now(),Date.now()-this.start,++this.num]});
			}
		}
	}
    return true;
  }
});`
        ],
        { type: 'application/javascript; charset=utf-8' }
      )
      const url = URL.createObjectURL(blob)
      await this._audioContext.audioWorklet.addModule(url)
      this._recorderNode = new AudioWorkletNode(
        this._audioContext,
        'pcm-processor'
      )
      this._recorderNode.connect(this._audioContext.destination)
      port = this._recorderNode.port
      this._source.connect(this._recorderNode)
    }

    port.onmessage = ({ data }: { data: any }) => {
      const blob = new Blob([data.data.buffer])
      if (this._dg && this._dgConnected) {
        this._dg.send(blob)
      } else {
        console.log('ignore the blob, dbConnected', this._dgConnected)
      }
    }

    this._transcriberConnect(this._audioContext.sampleRate)
  }
}

export default RAudio
