import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  ForwardRefRenderFunction,
  forwardRef,
  Ref,
  RefObject,
  MutableRefObject
} from 'react'
import get from 'lodash/get'
import set from 'lodash/set'

import RAudio from 'shared/components/recorder/Raudio'
import RVideo from 'shared/components/recorder/Rvideo'
import { ControlT, InteractionT } from 'shared/types/facesign'
import SilenceDetector from 'shared/components/SilenceDetector'
import RecorderControls from 'shared/components/recorder/RecorderControls'

export interface IRecorder {
  stop: () => void
  start: () => void
  cancel: () => void
  recordingStop: () => void
  isRecording: () => boolean
}

type Props = {
  interactionId: string
  onCameraOn?: () => void
  onPhrase: (speech: string, dgLatency: number) => void
  handleChunk: (videoBlob: Blob, mimeType: string, role: 'user') => void
  recordingStatusChanged?: (isRecording: boolean) => void
  onSpeech?: (v: string[]) => void
  cameraVideoRef?: RefObject<HTMLVideoElement>
  isActive: boolean
  setThereWasAnError?: (v: string) => void
  permissionsError?: string | null
  controls: ControlT
  streamRef: MutableRefObject<MediaStream | null>
  setControls: (c: ControlT) => void
  logEvent: (eventType: InteractionT.LogTypeT, data?: object) => void
  dgKey: string
}

const VideoRecorder: ForwardRefRenderFunction<IRecorder, Props> = (
  {
    interactionId,
    handleChunk,
    onCameraOn,
    recordingStatusChanged,
    onSpeech,
    cameraVideoRef,
    isActive,
    onPhrase,
    setThereWasAnError,
    permissionsError,
    controls,
    streamRef,
    setControls,
    logEvent,
    dgKey
  },
  ref: Ref<unknown>
) => {
  const isRecordingRef = useRef(false)
  const [error, setError] = useState<string>()
  const [isInlineRecordingSupported, setIsInlineRecordingSupported] = useState<
    boolean | null
  >(null)
  const [initPass, setInitPass] = useState<boolean>(false)

  const videoRecorderRef = useRef<RVideo>()
  const audioRecorderRef = useRef<RAudio>()
  const silenceDetectedAt = useRef<number>()

  useImperativeHandle(ref, () => ({
    stop: () => {
      stop()
    },
    start: () => {
      start()
    },
    cancel: () => {
      cancel()
    },
    recordingStop: () => {
      recordingStop()
    },
    isRecording: () => {
      return isRecordingRef.current
    }
  }))

  useEffect(() => {
    init()
    return finilize
  }, [])

  const setIsRecording = (v: boolean) => {
    isRecordingRef.current = v
    silenceDetectedAt.current = undefined
    if (recordingStatusChanged) {
      recordingStatusChanged(v)
    }
  }

  useEffect(() => {
    if (!initPass && controls.camera && controls.mic) {
      setInitPass(true)
      turnOnCamera()
    }
  }, [controls, initPass])

  const setSpeech = (s: string[]) => {
    onSpeech && onSpeech(s)
  }

  const init = () => {
    logEvent('RECORDER: init')

    const _isInlineRecordingSupported =
      !!window.MediaRecorder && !!navigator.mediaDevices
    logEvent('RECORDER: init', { _isInlineRecordingSupported })
    if (!_isInlineRecordingSupported) {
      logEvent('WARNINIG | RECORDER: inline recording is not supported')
    }
    setIsInlineRecordingSupported(_isInlineRecordingSupported)
  }

  const recordingStop = () => {
    console.log('recording stop')
    videoRecorderRef.current?.stop()
  }

  const initAudioRecorder = () => {
    console.log('---> initAudioRecorder')
    logEvent('RECORDER: init audio recorder')
    audioRecorderRef.current = new RAudio(
      streamRef.current,
      onSpeach,
      onFinalDetected,
      logEvent,
      interactionId,
      dgKey
    )
    // audioRecorderRef.current.start()
  }

  const finilize = () => {
    console.log('finilize')
    audioRecorderRef.current?.stop()
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop())
    }
  }

  const turnOnCamera = async () => {
    console.log('turnOnCamera')
    logEvent('Recorder | turnOnCamera: start')
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    console.log('mediaDevices', mediaDevices)
    mediaDevices.forEach(d =>
      console.log('mediaDevice', d.deviceId, d.label, d.kind, d.toJSON())
    )
    logEvent('Recorder | media devices found', mediaDevices)
    const videoDevices = mediaDevices.filter(x => x.kind === 'videoinput')
    logEvent('Recorder | video devices found', videoDevices)
    const currentDeviceId = get(videoDevices, [0, 'deviceId'])
    logEvent('Recorder | the first video device is', { currentDeviceId })
    console.log('current device id', currentDeviceId)
    try {
      const constraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: false,
          autoGainControl: false,
          mozAutoGainControl: false,
          mozNoiseSuppression: false
        },
        video: {
          // deviceId: {
          //   exact: currentDeviceId
          // }
          frameRate: 12
          // aspectRatio: 844 / 390,
          // width: { ideal: 390 },
          // height: { ideal: 844 }
        }
      }

      if (currentDeviceId) {
        set(constraints, 'video.deviceId.exact', currentDeviceId)
      }

      logEvent('Recorder | navigator.mediaDevices.getUserMedia call', {
        constraints
      })

      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      logEvent(
        'Recorder | navigator.mediaDevices.getUserMedia done, stream created',
        { streamId: stream.id }
      )
      streamRef.current = stream
      initAudioRecorder()
      if (cameraVideoRef && cameraVideoRef.current) {
        cameraVideoRef.current.srcObject = stream
        onCameraOn && onCameraOn()
      }
      if (stream) {
        videoRecorderRef.current = new RVideo(
          streamRef.current,
          onVideoRecordingStopped,
          onNewChunk
        )
      }
      // }
    } catch (e) {
      logEvent('ERROR: Recorder | turnOnCamera failed', { msg: e.message })
      console.error(e)
      setError(get(e, 'message'))
      if (setThereWasAnError) setThereWasAnError(get(e, 'message', ''))
    }
  }

  const onVideoRecordingStopped = async () => {
    // audioRecorderRef.current?.stop()
  }

  const onSpeach = (s: string[]) => setSpeech(s)

  const onFinalDetected = (currentTranscript: [string, string]) => {
    console.log('onFinalDetected, is user recording', isRecordingRef.current)
    logEvent('End of speech Detected', {
      transcript: currentTranscript,
      userIsFocused: isRecordingRef.current
    })
    if (isRecordingRef.current) {
      stop()
    }
    const latency =
      silenceDetectedAt.current > 0
        ? Date.now() - silenceDetectedAt.current + 3000
        : 0
    logEvent('stop recording the user')
    if (currentTranscript[0] !== '') {
      onPhrase(currentTranscript[0], latency)
    }
    if (currentTranscript[1] !== '') {
      onPhrase(currentTranscript[1], latency)
    }
    // onPhrase(audioRecorderRef.current?.speach || ['', ''], latency)
  }

  const start = async () => {
    console.log('%cRecorder Start', 'color: orange;')
    logEvent('focus switched to the user')
    setSpeech(['', ''])
    setIsRecording(true)
    // audioRecorderRef.current?.start()
  }

  const stop = () => {
    console.log('%cRecorder Stop', 'color: orange;')
    setIsRecording(false)
    // videoRecorderRef.current?.stop()
    // audioRecorderRef.current?.stop()
  }

  const cancel = () => {
    console.log('%cRecorder Cancel', 'color: orange;')
    setIsRecording(false)
    // audioRecorderRef.current?.stop()
  }

  const handleMicOrCameraClick = (type: 'mic' | 'camera') => {
    logEvent(`RECORDER: handleMicOrCameraClick: ${type}`, {
      newControls: { ...controls, [type]: !controls[type] }
    })
    setControls({ ...controls, [type]: !controls[type] })
  }

  const onNewChunk = (blob: Blob) => {
    const mimeType = videoRecorderRef.current.mimeType
    handleChunk(blob, mimeType, 'user')
  }

  const renderControls = () => {
    return (
      <RecorderControls
        controls={controls}
        handleMicOrCameraClick={handleMicOrCameraClick}
      />
    )
  }

  const onSilenceDetected = () => {
    console.log('onSilenceDetected')
    // onFinalDetected()
    logEvent('RECORDER: silence detected')
    silenceDetectedAt.current = Date.now()
  }

  const renderCameraView = () => {
    if (!isInlineRecordingSupported && isInlineRecordingSupported !== null) {
      return <p>This browser is uncapable of recording video</p>
    }

    if (error) {
      return <p> Error {error}</p>
    }

    return (
      <div key='camera' className='w-full h-full rounded-md'>
        {renderControls()}
        <video
          className={`w-full h-full object-cover rounded-md scale-x-[-1] ${
            !controls?.camera || !controls.mic ? 'opacity-80' : 'opacity-100'
          }`}
          ref={cameraVideoRef}
          autoPlay
          muted
          playsInline
        />
        <SilenceDetector
          streamRef={streamRef}
          isRecording={isActive}
          onSilenceDetected={onSilenceDetected}
          delay={3000}
        />
      </div>
    )
  }

  if (permissionsError) return null

  return (
    <div
      className={
        'absolute top-0 left-0 bottom-0 right-0 w-full h-full rounded-md border' +
        (isActive ? ' border-teal-400' : ' border-transparent')
      }
    >
      {renderCameraView()}
      {!isActive && (
        <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full rounded-md shadow-lg bg-blackAlpha-400' />
      )}
    </div>
  )
}

export default forwardRef(VideoRecorder)
