export enum LocationT {
  WIDTHAI = 'WIDTHAI',
  CHATGPT = 'CHATGPT'
}

export const getLocation = () => {
  return LocationT.CHATGPT
  // if (window.location.host.split('.')[0] === 'demo') {
  //   return LocationT.CHATGPT
  // } else {
  //   return LocationT.WIDTHAI
  // }
}
