import { FC, RefObject, useEffect, useRef } from 'react'
import { InteractionT } from 'shared/types/facesign'

type Props = {
  streamRef: RefObject<MediaStream | null>
  onVoiceDetected: () => void
  level?: number
  logEvent: (eventType: InteractionT.LogTypeT, data?: object) => void
}

const VoiceDetector: FC<Props> = ({
  streamRef,
  level = -90,
  onVoiceDetected,
  logEvent
}) => {
  const analyzerRef = useRef<AnalyserNode | null>(null)
  const timer = useRef<number>(0)
  const domainDataRef = useRef<Uint8Array | null>(null)
  const fallbackTimeoutRef = useRef<number>(0)

  const detectSound = () => {
    if (analyzerRef.current && domainDataRef.current) {
      const bufferLength = analyzerRef.current.frequencyBinCount
      console.log('detectSound, bufferLength', bufferLength)
      analyzerRef.current.getByteFrequencyData(domainDataRef.current)
      let detected = false

      // console.log('detected', detected)

      for (let i = 0; i < bufferLength; i++) {
        if (domainDataRef.current[i] > 0) {
          console.log(
            'voice detected at freq',
            i,
            'with level',
            domainDataRef.current[i]
          )
          detected = true
          break
        }
      }

      if (detected) {
        onVoiceDetected()
      }
    }
  }

  useEffect(() => {
    // Voice Analizer
    if (streamRef.current) {
      fallbackTimeoutRef.current = window.setTimeout(() => {
        logEvent('WARNING: VoiceDetector could not detect sound during 3 sec')
        onVoiceDetected()
      }, 3000)
      console.log('start sound analysing')

      const audioContext = new AudioContext()
      console.log('audioContext created', audioContext)
      const audioStreamSource = audioContext.createMediaStreamSource(
        streamRef.current
      )
      console.log('audioStreamSource created', audioStreamSource)
      const analyser = audioContext.createAnalyser()

      analyser.fftSize = 256
      analyser.minDecibels = level
      analyser.maxDecibels = -10
      analyser.smoothingTimeConstant = 0.85
      audioStreamSource.connect(analyser)
      analyzerRef.current = analyser

      console.log('analyser created', audioStreamSource)

      const bufferLength = analyser.frequencyBinCount
      domainDataRef.current = new Uint8Array(bufferLength)

      timer.current = window.setInterval(detectSound, 500)
    } else {
      clearInterval(timer.current)
    }

    return () => {
      clearInterval(timer.current)
      clearTimeout(fallbackTimeoutRef.current)
    }
  }, [])

  return null

  // return (
  //   <div className='bg-whiteAlpha-400 z-10'>
  //     <p>
  //       {soundDetected ? 'sound' : 'silence'} - {lastSoundTime}
  //     </p>
  //     <p>
  //       {initialSoundDetected ? 'waiting for silence' : 'waiting for voice'} -{' '}
  //       {lastSoundTime}
  //     </p>
  //     <p>isRecording: {isRecording.toString()}</p>
  //   </div>
  // )
}

export default VoiceDetector
